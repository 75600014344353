<template>
  <div>
      <card class="shadow" body-classes="standard-card-body">
        <template slot="header">
          <div class="d-flex align-items-center">
            <h4 class="card-title text-capitalize">{{ $t('deal.installments_number') }}</h4>
            <base-button
              link icon size="sm"
              class="ml-auto text-capitalize"
              @click="() => this.showModal = true">
              <octo-icon icon="add"/>
            </base-button>
          </div>
        </template>
        <div>
          <ul class="list-group list-group-flush">
            <list-group-item-component
              v-bind:key="`list-keys-${index}`"
              v-for="(setting, index) in enableInstallmentPlans"
              :label="setting.type"
            >
              <template slot="value">
                <div>
                  {{setting.value}} %
                  <base-button
                    link icon size="sm"
                    confirm-required
                    @click="deleteElement(setting)">
                    <octo-icon icon="wrong"/>
                  </base-button>
                </div>
              </template>
            </list-group-item-component>
          </ul>
        </div>
      </card>

      <modal modalClasses="modal-sm" bodyClasses="px-2" centered :show.sync="showModal">
        <h5 slot="header" class="modal-title text-uppercase">
          {{ $t('common.select') }}
        </h5>
        <ValidationObserver ref="settingsForm">
        <ul class="list-group list-group-flush">
          <list-group-item-component :label="$t('deal.installments_number')" :label-width="labelWidth">
            <template slot="value">
              <ValidationProvider
                class="flex-grow-1"
                name="installments_number"
                rules="required"
                v-slot="{ passed, failed, errors }"
              >
              <base-input class="mb-0">
                <el-select
                  class="select-default text-uppercase"
                  :placeholder="$t('common.select')"
                  v-model="installmentPlanSelected.type"
                  :error="errors[0]"
                  :class="[{ 'has-success-select': passed }, { 'has-danger-select': failed }]">
                  <el-option
                    v-for="(available, index) in availableInstallmentPlans"
                    v-bind:key="`user-profile-${index}`"
                    class="select-default text-uppercase"
                    :value="available.type"
                    :label="available.type"
                  />
                </el-select>
              </base-input>
              </ValidationProvider>
            </template>
          </list-group-item-component>
          <list-group-item-component :label="$t('fields.value')" :label-width="labelWidth">
            <template slot="value">
              <ValidationProvider
                class="flex-grow-1"
                name="value"
                rules="required|numeric|between:1,99"
                v-slot="{ passed, failed, errors }"
              >
              <base-input class="mb-0"
                          addon-right-text="%"
                          v-model="installmentPlanSelected.value"
                          :error="errors[0]"
                          :class="[{ 'has-success': passed }, { 'has-danger': failed }]"/>
              </ValidationProvider>
            </template>
          </list-group-item-component>
        </ul>
        </ValidationObserver>
        <template slot="footer">
          <base-button link class="text-capitalize" @click="addPaymentPlans()">{{$t('common.save')}}</base-button>
        </template>
      </modal>

    </div>

</template>

<script>

import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import Modal from "@/components/Modal";
import Deal from "@/models/deal";
import {endpoints} from "@/routes/endpoints";
import {mapActions, mapGetters} from "vuex";
import {Option, Select} from "element-ui";

export default {
  name: "UserInstallmentPlansBonuses",
  components:{
    Modal,
    OctoIcon,
    LabelThemeComponent,
    ListGroupItemComponent,
    [Select.name]: Select,
    [Option.name]: Option
  },

  data() {
    return {
      showModal: false,
      installmentPlans: [],
      labelWidth: 200,
      installmentPlanSelected: {value:null, type:null}
    }
  },
  computed: {
    ...mapGetters({
      settings: 'common/settings'
    }),
    enableInstallmentPlans(){
      return this.$_.filter(this.installmentPlans, (setting) => !!setting.value)
    },
    availableInstallmentPlans(){
      return this.$_.filter(this.installmentPlans, (setting) => !setting.value)
    }
  },
  mounted() {
    this.installmentPlans = this.$_.cloneDeep(this.settings.installment_plan);
  },
  methods:{

    ...mapActions({
      updateSettingsByName: 'common/updateSettingsByName'
    }),


    addPaymentPlans: async function (){
      try {
        if(!await this.$refs.settingsForm.validate()) {
          return;
        }
        this.$fullLoading.show();
        this.installmentPlans = this.$_.map(this.installmentPlans, (item) => {
          return {
            value: item.type === this.installmentPlanSelected.type ? this.installmentPlanSelected.value : item.value,
            type: item.type
          }
        });
        await this.updateSettings();
        this.showModal = false;
      } catch (e) {
        this.$notify({type: 'danger', message: this.$t('common.wrong_field')});
      } finally {
        this.$fullLoading.hide();
      }
    },

    deleteElement: async function(installmentPlan){
      try {
        this.$fullLoading.show();
        this.installmentPlans = this.$_.map(this.installmentPlans, (item)  => {
          return {
            value: item.type === installmentPlan.type ? 0 : item.value,
            type: item.type
          }
        });
        await this.updateSettings();
      }catch (e){
        this.$notify({type: 'danger', message: this.$t('common.wrong_field')});
      }finally{
        this.$fullLoading.hide();
      }
    },

    updateSettings: async function () {
      await this.updateSettingsByName({
        name: 'installment_plan',
        value: this.installmentPlans,
      })
    }
  }
}
</script>

<style scoped>

</style>
