<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-plain">
          <tabs centered square>
            <tab-pane>
              <template slot="label">
                <div class="d-flex align-items-center">
                  <div class="text-uppercase m-3">{{ $t('fields.sales') }}</div>
                </div>
              </template>
              <sales-thresholds />
              <sales-manager-commissions />
            </tab-pane>
            <tab-pane>
              <template slot="label">
                <div class="d-flex align-items-center">
                  <div class="text-uppercase m-3">{{ $t('sidebar.salaries') }}</div>
                </div>
              </template>
              <octo-header-button @onSave="saveSalaries" :buttons="headerButtons"/>
              <users-salaries ref="salesSalaries" :users-list="sales" :title="$t('fields.sales')"/>
            </tab-pane>
            <tab-pane>
              <template slot="label">
                <div class="d-flex align-items-center">
                  <div class="text-uppercase m-3">{{ $t('sidebar.bonuses') }}</div>
                </div>
              </template>
              <users-bonuses  :title="$t('fields.bonuses')"/>
            </tab-pane>
          </tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import {TabPane, Tabs} from "@/components";
import UsersSalaries from "./components/UsersSalaries";
import {endpoints} from "@/routes/endpoints";
import SalesSettings from "./components/SalesSettings";
import SalesThresholds from "@/pages/Settings/components/SalesThresholds.vue";
import UsersBonuses from "@/pages/Settings/components/UsersBonuses.vue";
import SalesManagerCommissions from "@/pages/Settings/components/SalesManagerCommissions.vue";

export default {
  name: "SalesPage",
  components: {
    SalesManagerCommissions,
    UsersBonuses,
    SalesThresholds,
    SalesSettings,
    UsersSalaries,
    OctoHeaderButton,
    Tabs,
    TabPane,
  },
  data() {
    return {
      headerButtons: [
        {
          label: 'save',
          onClick: 'onSave',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      sales: 'common/sales'
    })
  },

  methods: {
    ...mapActions({
      updateSettings: 'common/updateSettings',
      getUsers: 'common/getUsers',
    }),

    ...mapMutations({
      setHelper: 'common/setHelper',
    }),

    saveSalaries: async function () {
      try {
        let salesData = await this.$refs.salesSalaries.getUsersData();
        this.$fullLoading.show();
        await this.$api.put(endpoints.USER_UPDATE_SALARIES, {salaries: salesData})
        await this.getUsers();

        this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
      } catch (ex) {
        this.$notify({type: 'danger', message: this.$t('common.wrong_field')});
      } finally {
        this.$fullLoading.hide();
      }
    },
  },

  beforeDestroy() {
    this.setHelper()
  }
}
</script>

<style scoped lang="scss">

</style>
