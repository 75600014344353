<template>
  <div class="row">
    <div class="col-6">
      <user-installment-plans-bonuses></user-installment-plans-bonuses>
    </div>
    <div class="col-6">
      <users-payment-methods></users-payment-methods>
    </div>
  </div>
</template>

<script>

import UserInstallmentPlansBonuses from "@/pages/Settings/components/UsersInstalmentPlansBonuses.vue";
import UsersPaymentMethods from "@/pages/Settings/components/UsersPaymentMethods.vue";

export default {
  name: "UsersBonuses",
  components: {UsersPaymentMethods, UserInstallmentPlansBonuses}
}
</script>

<style scoped>

</style>
