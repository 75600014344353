<template>
  <div class="container-fluid px-0">
    <div class="row">
      <div class="col-12">
        <card class="shadow" body-classes="standard-card-body">
          <template slot="header">
            <div class="d-flex align-items-center">
              <h4 class="card-title text-capitalize">{{ $t('deal.sales_thresholds') }}</h4>
              <base-button link icon size="sm" class="ml-auto text-capitalize" @click="openModal()">
                <octo-icon icon="add"/>
              </base-button>
            </div>
          </template>
          <el-table stripe :data="salesThresholds">
            <el-table-column align="center" :label="$t('callcenter.threshold')">
              <div slot-scope="{ row }">
                {{ row.threshold }}
              </div>
            </el-table-column>
            <el-table-column align="center" :label="$t('callcenter.value_over_threshold') + ' (%)'">
              <div slot-scope="{ row }">
                {{ row.value }}
              </div>
            </el-table-column>
            <el-table-column align="right" width="120px">
              <div slot-scope="{ row, $index }" class="d-flex justify-content-end">
                <base-button icon link class="mx-1" size="sm" @click="openModal($index)">
                  <octo-icon icon="edit"/>
                </base-button>
                <base-button link icon class="mx-1" size="sm" type="danger" @click="deleteThreshold($index)">
                  <octo-icon icon="wrong"/>
                </base-button>
              </div>
            </el-table-column>
          </el-table>
        </card>
      </div>
      <div class="col-12 col-md-6">
        <card class="shadow" body-classes="standard-card-body">
          <template slot="header">
            <h4 class="card-title text-capitalize">
              {{ $t('deal.installments_number') }}
            </h4>
          </template>
          <ul class="list-group list-group-flush">
            <list-group-item-component
              v-for="(installment, index) in settingsLocal[settingConst.INSTALLMENTS_PLAN]"
              v-bind:key="`installments-${index}`"
              :label="installment.type"
            >
              <template slot="value">
                <base-input v-model="installment.value" type="number" class="mb-0">
                  <template slot="addonRight">
                    <span class="input-group-append input-group-text">%</span>
                  </template>
                </base-input>
              </template>
            </list-group-item-component>
          </ul>
        </card>
      </div>
      <div class="col-12 col-md-6">
        <card class="shadow" body-classes="standard-card-body">
          <template slot="header">
            <h4 class="card-title text-capitalize">
              {{ $t('fields.payment_type') }}
            </h4>
          </template>
          <ul class="list-group list-group-flush">
            <list-group-item-component
              v-for="(payment, index) in settingsLocal[settingConst.PAYMENTS_TYPE]"
              v-bind:key="`payments-${index}`"
              :label="$t('deal.payment_types.' + payment.type)"
            >
              <template slot="value">
                <base-input v-model="payment.value" type="number" class="mb-0">
                  <template slot="addonRight">
                      <span class="input-group-append input-group-text">%</span>
                  </template>
                </base-input>
              </template>
            </list-group-item-component>
          </ul>
        </card>
      </div>
    </div>

    <modal modalClasses="modal-sm" bodyClasses="px-2" centered :show.sync="showModal">
      <h5 slot="header" class="modal-title text-uppercase">
        {{ modalTitle }}
      </h5>
      <ul class="list-group list-group-flush">
        <list-group-item-component :label="$t('callcenter.threshold')" :label-width="labelWidth">
          <template slot="value">
            <base-input required v-model="currentThreshold.threshold" type="text" class="mb-0"/>
          </template>
        </list-group-item-component>
        <list-group-item-component :label="$t('callcenter.value_over_threshold')" :label-width="labelWidth">
          <template slot="value">
            <base-input required v-model="currentThreshold.value" type="text" class="mb-0"/>
          </template>
        </list-group-item-component>
      </ul>

      <template slot="footer">
        <base-button link @click="saveCurrentThreshold">
          {{ $t('common.save') }}
        </base-button>
      </template>
    </modal>
  </div>
</template>

<script>
import Slider from "@/components/Slider";
import {mapGetters} from "vuex";
import settingConst from "@/pages/Settings/resources/settingConst";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import {Option, Select, Table, TableColumn} from "element-ui";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import Modal from "@/components/Modal";

const ThresholdModel = {
  threshold: 0,
  value: 0,
};

export default {
  name: "SalesSettings",
  components: {
    OctoIcon,
    ListGroupItemComponent,
    Slider,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Option.name]: Option,
    [Select.name]: Select,
    Modal
  },
  data() {
    return {
      labelWidth: 250,
      settingsLocal: {},
      settingConst: settingConst,
      data: [],
      modalTitle: this.$t('callcenter.new_threshold'),
      showModal: false,
      currentThreshold: this.$_.cloneDeep(ThresholdModel),
      currentThresholdIndex: null
    }
  },
  computed: {
    ...mapGetters({
      settings: 'common/settings'
    }),
    salesThresholds() {
      return this.$_.orderBy(this.settingsLocal?.[settingConst.SALES_THRESHOLDS], 'type') || []
    },
  },
  beforeMount() {
    this.settingsLocal = this.$_.cloneDeep(
      this.$_.pick(this.settings, [
        settingConst.SALES_THRESHOLDS,
        settingConst.INSTALLMENTS_PLAN,
        settingConst.PAYMENTS_TYPE,
      ])
    );
  },
  methods: {
    getSettings: function () {
      return [
        {
          name: settingConst.SALES_THRESHOLDS,
          value: this.settingsLocal?.[settingConst.SALES_THRESHOLDS] || []
        },
        {
          name: settingConst.INSTALLMENTS_PLAN,
          value: this.settingsLocal?.[settingConst.INSTALLMENTS_PLAN] || []
        },
        {
          name: settingConst.PAYMENTS_TYPE,
          value: this.settingsLocal?.[settingConst.PAYMENTS_TYPE] || []
        },
      ]
    },

    openModal: function (index) {
      const target = this.settingsLocal?.[settingConst.SALES_THRESHOLDS]?.[index];

      this.modalTitle = !index && index !== 0
        ? this.$t('callcenter.new_threshold')
        : this.$t('callcenter.edit_threshold');

      this.currentThreshold = this.$_.cloneDeep(target || ThresholdModel);
      this.currentThresholdIndex = index;

      this.showModal = true;
    },

    deleteThreshold: function (index) {
      this.settingsLocal?.[settingConst.SALES_THRESHOLDS].splice(index, 1)
    },

    saveCurrentThreshold: function () {
      if (this.currentThresholdIndex || this.currentThresholdIndex === 0) {
        this.$set(
          this.settingsLocal?.[settingConst.SALES_THRESHOLDS],
          this.currentThresholdIndex,
          this.$_.cloneDeep(this.currentThreshold)
        );
      } else {
        this.settingsLocal?.[settingConst.SALES_THRESHOLDS].push(this.$_.cloneDeep(this.currentThreshold))
      }

      this.currentThreshold = this.$_.cloneDeep(ThresholdModel);
      this.showModal = false;
    },
  }
}
</script>

<style scoped>

</style>
