<template>
  <div>
      <card class="shadow" body-classes="standard-card-body">
        <template slot="header">
          <div class="d-flex align-items-center">
            <h4 class="card-title text-capitalize">{{ $t('fields.payment_type') }}</h4>
            <base-button
              link icon size="sm"
              class="ml-auto text-capitalize"
              @click="() => this.showModal = true">
              <octo-icon icon="add"/>
            </base-button>
          </div>
        </template>
        <div>
          <ul class="list-group list-group-flush">
            <list-group-item-component
              class="d-flex justify-content-between"
              v-bind:key="`list-keys-${index}`"
              v-for="(setting, index) in enablePaymentMethods"
              :label="setting.type.replaceAll('_', ' ')"
            >
              <template slot="value">
                <div>
                  {{setting.value}}%
                  <base-button
                    link icon size="sm"
                    confirm-required
                    class="ml-auto text-capitalize"
                    @click="deleteElement(setting)"
                  >
                    <octo-icon icon="wrong"/>
                  </base-button>
                </div>
              </template>
            </list-group-item-component>
          </ul>
        </div>
      </card>

    <modal modalClasses="modal-sm" bodyClasses="px-2" centered :show.sync="showModal">
      <h5 slot="header" class="modal-title text-uppercase">
        {{ $t('common.select') }}
      </h5>
      <ValidationObserver ref="settingsForm">
      <ul class="list-group list-group-flush">
        <list-group-item-component :label="$t('fields.payment_type')" :label-width="labelWidth">
          <template slot="value">
            <ValidationProvider
              class="flex-grow-1"
              name="payment_type"
              rules="required"
              v-slot="{ passed, failed, errors }"
            >
            <base-input class="mb-0">
              <el-select
                class="select-default text-uppercase"
                :placeholder="$t('common.select')"
                v-model="paymentMethod.type"
                :class="[{ 'has-success-select': passed }, { 'has-danger-select': failed }]">

              <el-option
                  v-for="(available, index) in availablePaymentMethods"
                  v-bind:key="`user-profile-${index}`"
                  class="select-default text-uppercase"
                  :value="available.type"
                  :label="available.type.replaceAll('_', ' ')"
                />
              </el-select>
            </base-input>
            </ValidationProvider>
          </template>
        </list-group-item-component>
        <list-group-item-component :label="$t('fields.value')" :label-width="labelWidth">
          <template slot="value">
            <ValidationProvider
              class="flex-grow-1"
              name="value"
              rules="required|numeric|between:1,99"
              v-slot="{ passed, failed, errors }"
            >
            <base-input class="mb-0"
                        type="number"
                        v-model="paymentMethod.value"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]"/>
            </ValidationProvider>
          </template>
        </list-group-item-component>
      </ul>
      </ValidationObserver>
      <template slot="footer">
        <base-button link class="text-capitalize" @click="addPaymentsMethods()">{{$t('common.save')}}</base-button>
      </template>
    </modal>


    </div>


</template>

<script>



import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import Modal from "@/components/Modal";
import {mapActions, mapGetters} from "vuex";
import {Option, Select} from "element-ui";



export default {
  name: "UsersPaymentMethods",
  components:{
    Modal,
    OctoIcon,
    LabelThemeComponent,
    ListGroupItemComponent,
    [Select.name]: Select,
    [Option.name]: Option
  },

  data() {
    return {
      showModal: false,
      paymentMethods: [],
      paymentMethod: {value:null, type:null},
      labelWidth: 220
    }
  },
  computed: {
    ...mapGetters({
      settings: 'common/settings'
    }),


    enablePaymentMethods(){
      return this.$_.filter(this.paymentMethods, (setting) => !!setting.value)


    },
    availablePaymentMethods(){
      return this.$_.filter(this.paymentMethods, (setting) => !setting.value)
    }
  },
  mounted() {
    this.paymentMethods = this.$_.cloneDeep(this.settings.payment_type);


  },
  methods:{

    ...mapActions({
      updateSettingsByName: 'common/updateSettingsByName'
    }),

    addPaymentsMethods: async function (){
      try {
        if(!await this.$refs.settingsForm.validate()) {
          return;
        }
        this.$fullLoading.show();
        this.paymentMethods = this.$_.map(this.paymentMethods, (item) => {
          return {
            value: item.type === this.paymentMethod.type ? this.paymentMethod.value : item.value,
            type: item.type
          }
        });
        await this.updateSettings();
        this.showModal = false;


      }catch (e) {
        console.log(e);
        this.$notify({type: 'danger', message: this.$t('common.wrong_field')});

      }finally {
        this.$fullLoading.hide();
      }
    },


    deleteElement: async function(paymentMethod){

      try {
        this.$fullLoading.show();
        this.paymentMethods = this.$_.map(this.paymentMethods, (item)  => {
          return {
            value: item.type === paymentMethod.type ? 0 : item.value,
            type: item.type
          }
        });

        await this.updateSettings();
      }catch (e){
        this.$notify({type: 'danger', message: this.$t('common.wrong_field')});
      }finally{
        this.$fullLoading.hide();
      }
    },

    updateSettings: async function () {
      await this.updateSettingsByName({
        name: 'payment_type',
        value: this.paymentMethods,
      })
    }
  }
}
</script>

<style scoped>

</style>
