<template>
  <div class="container-fluid px-0">
    <div class="row">
      <div class="col-12">
        <card class="shadow" body-classes="standard-card-body">
          <template slot="header">
            <div class="d-flex align-items-center">
              <h4 class="card-title text-capitalize">{{ $t('deal.sales_thresholds') }} default</h4>
              <base-button link icon size="sm" class="ml-auto text-capitalize" @click="openModal(null, false)">
                <octo-icon icon="add"/>
              </base-button>
            </div>
          </template>
          <el-table stripe :data="defaultSaleThresholds">
            <el-table-column align="center" :label="$t('callcenter.threshold')">
              <template #default="scope">
                {{ scope.row.threshold | euro }}
              </template>
            </el-table-column>
            <el-table-column align="center" :label="$t('callcenter.value_over_threshold')">
              <template #default="scope">
                {{ scope.row.value }} %
              </template>
            </el-table-column>
            <el-table-column align="right" width="120px">
              <template #default="scope" class="d-flex justify-content-end">
                <base-button icon link class="mx-1" size="sm"  @click="openModal(scope.row, false)">
                  <octo-icon icon="edit"/>
                </base-button>
                <base-button link icon confirmRequired class="mx-1" size="sm" type="danger"
                             @click="deleteElement(scope.row.id)">
                  <octo-icon icon="wrong"/>
                </base-button>
              </template>
            </el-table-column>
          </el-table>
        </card>
      </div>
      <div class="col-12">
        <card class="shadow" body-classes="standard-card-body">
          <template slot="header">
            <div class="d-flex align-items-center">
              <h4 class="card-title text-capitalize">{{ $t('deal.sales_thresholds') }} custom</h4>
              <base-button link icon size="sm" class="ml-auto text-capitalize"  @click="openModal(null, true)">
                <octo-icon icon="add"/>
              </base-button>
            </div>
          </template>
          <el-table stripe :data="customSaleThresholds">
            <el-table-column align="left" :label="$t('callcenter.value_over_threshold')">
              <template #default="scope">
                <div class="text-uppercase">{{ scope.row.lastname }} {{ scope.row.firstname }}</div>
              </template>
            </el-table-column>
            <el-table-column align="center" :label="$t('callcenter.threshold')">

              <template #default="scope">
                {{ scope.row.threshold | euro }}
              </template>
            </el-table-column>
            <el-table-column align="center" :label="$t('callcenter.value_over_threshold')">
              <template #default="scope">
                {{ scope.row.value }} %
              </template>
            </el-table-column>
            <el-table-column align="right" width="120px">
              <template #default="scope" class="d-flex justify-content-end" >
                <base-button icon link class="mx-1" size="sm" @click="openModal(scope.row, true)">
                  <octo-icon icon="edit"/>
                </base-button>
                <base-button link icon confirmRequired class="mx-1" size="sm" type="danger"
                             @click="deleteElement(scope.row.id)">
                  <octo-icon icon="wrong"/>
                </base-button>
              </template>
            </el-table-column>
          </el-table>
        </card>
      </div>

    </div>

    <modal modalClasses="modal-sm" bodyClasses="px-2" centered :show.sync="showModal">
      <h5 slot="header" class="modal-title text-uppercase">
        {{ modalTitle }}
      </h5>

      <ValidationObserver ref="salesThresholdForm">
        <ul class="list-group list-group-flush">
          <list-group-item-component v-if="this.showSalesSelect" :label="$t('reports.wizard.sales')" :label-width="labelWidth">
            <template slot="value">
              <ValidationProvider
                class="flex-grow-1"
                name="user"
                rules="required"
                v-slot="{ passed, failed, errors }"
              >
                <base-input>
                  <el-select
                    clearable
                    class="select-default text-uppercase"
                    :placeholder="$t('reports.wizard.sales')"
                    v-model="saleThreshold.user_id"
                  >
                    <el-option
                      v-for="option in userOptions"
                      class="select-default text-uppercase"
                      :value="option.value"
                      :label="option.label"
                      :key="option.value"
                    >
                    </el-option>
                  </el-select>
                </base-input>
              </ValidationProvider>

            </template>
          </list-group-item-component>
          <list-group-item-component :label="$t('callcenter.threshold')" :label-width="labelWidth">
            <template slot="value">
              <ValidationProvider
                class="flex-grow-1"
                name="threshold"
                rules="required|numeric"
                v-slot="{ passed, failed, errors }"
              >
                <base-input required v-model="saleThreshold.threshold"  type="text" class="mb-0"/>
              </ValidationProvider>

            </template>
          </list-group-item-component>
          <list-group-item-component :label="$t('datatable.value')" :label-width="labelWidth">
            <template slot="value">
              <ValidationProvider
                class="flex-grow-1"
                name="value"
                rules="required|numeric|between:1,99"
                v-slot="{ passed, failed, errors }"
                >
                <base-input required v-model="saleThreshold.value" type="text" class="mb-0"/>
              </ValidationProvider>

            </template>
          </list-group-item-component>
        </ul>
      </ValidationObserver>


      <template slot="footer">
        <base-button @click="saveCurrentSaleBonus()">
          {{ buttonLabel }}
        </base-button>
      </template>
    </modal>

  </div>
</template>

<script>
import OctoIcon from "@/components/octo-icon/OctoIcon";
import {endpoints} from "@/routes/endpoints";
import {Option, Select, Table, TableColumn} from "element-ui";
import Modal from "@/components/Modal.vue";
import ListGroupItemComponent from "@/components/ListGroupItemComponent.vue";
import SaleThreshold from "@/models/salebonus";
import {mapGetters} from "vuex";
import {permissionService} from "@/util/permission-service";
import SaleBonus from "@/models/salebonus";

export default {
  name: "SalesThresholds",
  components: {
    ListGroupItemComponent,
    Modal,
    OctoIcon,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,

  },
  data() {
    return {
      saleThresholds: [],
      endpoints: endpoints,
      showModal: false,
      modalTitle: null,
      buttonLabel: null,
      showSalesSelect: false,
      labelWidth: 250,
      saleThreshold: this.$_.cloneDeep(SaleThreshold),
    }
  },
  computed: {

    ...mapGetters({
      users: 'common/users'
    }),

    userOptions() {

      const sales = this.$_.filter(this.users, user => {
        return this.$_.find(user.roles, role => {
          return role.name === permissionService.SALES_MANAGER_ROLE || role.name === permissionService.SALES_ROLE;
        })
      });
      return this.$_.orderBy(this.$_.map(sales, user => {
        return {
          label: (user?.lastname + ' ' + user?.firstname),
          value: user.id
        }
      }), 'label');
    },

    defaultSaleThresholds: function () {
      return this.$_.orderBy(this.$_.filter(this.saleThresholds, {user_id: null}), 'threshold')
    },
    customSaleThresholds: function () {
      return this.$_.orderBy(this.$_.filter(this.saleThresholds, (item) => {
        return item.user_id;
      }), ['lastname', 'threshold'])
    }
  },

  methods: {
    openModal: function (item, showSalesSelect) {
      this.modalTitle = item ? 'Aggiorna Bonus' : 'Inserisci Bonus';
      this.buttonLabel = item ? 'Aggiorna' : 'Inserisci';
      this.saleThreshold = this.$_.cloneDeep(item || SaleThreshold);
      this.saleThreshold.threshold = parseInt(this.saleThreshold.threshold)
      this.saleThreshold.value = parseInt(this.saleThreshold.value)
      this.showModal = true;
      this.showSalesSelect = showSalesSelect;

    },

    getSaleThresholds: async function () {
      try {
        const resp = await this.$api.post(endpoints.DATATABLES.saleThresholds, {
          pagination: false
        });
        this.saleThresholds = resp?.data?.collection;
      } catch (e) {

      }
    },
    saveCurrentSaleBonus: async function (){

      try {
        if(!await this.$refs.salesThresholdForm.validate()) {
          return;
        }
        this.$fullLoading.show();
        const data = {
          user_id: this.showSalesSelect ?  this.saleThreshold.user_id : null,
          threshold: this.saleThreshold.threshold,
          value: this.saleThreshold.value,
        }
        this.saleThreshold.id
          ? await  this.$api.put(endpoints.SALE_THRESHOLDS_UPDATE.replace('{id}', this.saleThreshold.id), data)
          : await  this.$api.post(endpoints.SALE_THRESHOLDS_STORE, data)

        await this.getSaleThresholds();

        this.showModal= false;
      }catch (e) {
        this.$notify({type: 'danger', message: this.$t('common.wrong_field')});

      }finally {
        this.$fullLoading.hide();
      }
    },
    deleteElement: async function (id) {
      try {
        this.$fullLoading.show();
        await this.$api.delete(endpoints.SALE_THRESHOLDS_DELETE.replace('{id}', id));
        await this.getSaleThresholds();
      } catch (e) {
        this.$notify({type: 'danger', message: this.$t('common.wrong_field')});
      } finally {
        this.$fullLoading.hide();
      }
    }
  },
  async mounted() {
    try {
      this.$fullLoading.show();
      await this.getSaleThresholds();
    } catch (e) {
    } finally {
      this.$fullLoading.hide();
    }

  }
}
</script>

<style scoped>

</style>
